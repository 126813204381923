@import url("https://fonts.googleapis.com/css?family=Indie+Flower&display=swap");

body {
  margin: 0;

  font-family: "Indie Flower", cursive, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  background-color: #00b4ff;
  color: #333;
}

html {
  font-size: 62.5%;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.intro {
  height: 100vh;
  width: 100%;
  max-width: 2000px;
  max-height: 1280px;
  min-height: 213px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background-image: url("./features/Intro/images/bg.jpg");
  background-size: cover;
  background-position: -3px 15%;
  background-repeat: no-repeat;
  overflow: hidden;
  @media screen and (orientation: portrait) {
    background-position-x: 65%;
  }
  @media screen and (orientation: portrait) and (min-height: 800px) {
    background-position-x: 60%;
  }
  @media screen and (max-width: 1000px) {
    background-position-x: 80%;
  }
  @media screen and (max-width: 384px) {
    background-position-x: 85%;
  }
}
